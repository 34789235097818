import React from 'react';
import { Stack } from 'react-bootstrap'
import SvgButton from 'components/SvgButton';
import 'bootstrap/dist/css/bootstrap.css';
import 'index.css';

class TempoNudge extends React.Component {
    constructor(props) {
        super(props);
        this.nudgeAmount = 0.03;
    }
    
    onTempoStartNudge = (direction) => {
        const nudgeAmount = direction === "Forward" ? this.nudgeAmount : -1 * this.nudgeAmount;
        this.props.setTempoChangePercentage(this.props.tempoChangePercentage + parseFloat(nudgeAmount));
    }
    
    onTempoStopNudge = (direction) => {
        const nudgeAmount = direction === "Forward" ? this.nudgeAmount : -1 * this.nudgeAmount;
        this.props.setTempoChangePercentage(this.props.tempoChangePercentage - parseFloat(nudgeAmount));
    }
    
    render() {
        return (
            <Stack direction="horizontal" gap={1}>
                <SvgButton text="<<" width="60px" height="35px" style={{paddingTop: "10px"}} onMouseDown={() => this.onTempoStartNudge("Backward")} onMouseUp={() => this.onTempoStopNudge("Backward")}/>
                <SvgButton text=">>" width="60px" height="35px" style={{paddingTop: "10px"}} onMouseDown={() => this.onTempoStartNudge("Forward")} onMouseUp={() => this.onTempoStopNudge("Forward")}/>
            </Stack>
        )
    }
}

export default TempoNudge;