import React from 'react';
import {Stack} from 'react-bootstrap'

class TempoOptions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLeftActive: false,
            isRightActive: false
        }
    }

    formatTempoRange = (tempoRange) => {
      return `±${parseInt(tempoRange)}%`;
    }

    render() {
      return (
          <div>
            <Stack direction="horizontal" gap={0}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill={"black"} onMouseDown={() => {this.setState({isLeftActive: true}); this.props.decrease();}} onMouseUp={() => {this.setState({isLeftActive: false});}}>
                  <rect fill={this.state.isLeftActive ? "0f0f0f" : "#1f1f1f"} stroke={"#0f0f0f"} strokeWidth={1} width="40" height="40" x={0}/>
                  <text x={"50%"} y="17" textAnchor="middle" style={{ userSelect: 'none', fill: 'white', fontSize: '12' }}>{"<"}</text>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="25" fill={"black"}>
                  <rect fill="#0f0f0f" stroke={"#0f0f0f"} strokeWidth={1} width="50" height="40" x={0}/>
                  <text x={"50%"} y="17" textAnchor="middle" style={{ userSelect: 'none', fill: 'white', fontSize: '12' }}>{this.formatTempoRange(this.props.tempoRange)}</text>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill={"black"} onMouseDown={() => {this.setState({isRightActive: true}); this.props.increase();}} onMouseUp={() => {this.setState({isRightActive: false});}}>
                  <rect fill={this.state.isRightActive ? "0f0f0f" : "#1f1f1f"} stroke={"#0f0f0f"} strokeWidth={1} width="40" height="40" x={0}/>
                  <text x={"50%"} y="17" textAnchor="middle" style={{ userSelect: 'none', fill: 'white', fontSize: '12' }}>{">"}</text>
              </svg>
            </Stack>
          </div>
      );
    }
}

export default TempoOptions;