import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'index.css';

class SvgButton extends React.Component {
  constructor(props) {
    super(props);
    this.state= {
      isActive: false,
    };
  }

  render() {
      return (
        <svg width={this.props.width} height={this.props.height} style={this.props.style} className={this.props.className} onMouseDown={() => {this.setState({isActive: true}); this.props.onMouseDown();}} onMouseUp={() => {this.setState({isActive: false}); this.props.onMouseUp();}}>
            <rect fill={this.state.isActive ? "#3f3f3f" : "#4f4f4f"} stroke="#9f9f9f" strokeWidth={2} width="100%" height="100%" />
            <text x={"50%"} y="17.5" textAnchor="middle" style={{ userSelect: 'none', fill: 'white', fontSize: '12' }}>{this.props.text}</text>
        </svg>
      )
    }
}

export default SvgButton;