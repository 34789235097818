import React from 'react';
import { SecondsToTime } from 'Utilities';
import 'bootstrap/dist/css/bootstrap.css';

class TrackRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadAButtonActive: false,
      isLoadBButtonActive: false
    }
  }
  
  onDragStart = (e) => {
    e.dataTransfer.setData("track/id", this.props.id);
    e.dataTransfer.setData("track/title", this.props.title);
    e.dataTransfer.setData("track/artist", this.props.artist);
    e.dataTransfer.setData("track/genre", this.props.genre);
    e.dataTransfer.setData("track/artwork", this.props.artwork);
  }  
  
  render() {
      return (
        <tr draggable="true" onDragStart={(e) => this.onDragStart(e)}>
          <td>
            <svg width={"40px"} height={"30px"} onMouseDown={() => { this.setState({isLoadAButtonActive: true}); this.props.onLoad("A", this.props.id, this.props.title, this.props.artist, this.props.genre, this.props.artwork);}} onMouseUp={() => this.setState({isLoadAButtonActive: false})}>
              <rect fill={this.state.isLoadAButtonActive ? "#3f3f3f" : "#4f4f4f"} width="40" height="30" x={0}/>
              <text x="50%" y="20" textAnchor="middle" style={{ userSelect: 'none', fill: 'white', fontSize: '16' }}>A</text>
            </svg>
            {' '}
            <svg width={"40px"} height={"30px"} onMouseDown={() => { this.setState({isLoadBButtonActive: true}); this.props.onLoad("B", this.props.id, this.props.title, this.props.artist, this.props.genre, this.props.artwork);}} onMouseUp={() => this.setState({isLoadBButtonActive: false})}>
              <rect fill={this.state.isLoadBButtonActive ? "#3f3f3f" : "#4f4f4f"} width="40" height="30" x={0}/>
              <text x="50%" y="20" textAnchor="middle" style={{ userSelect: 'none', fill: 'white', fontSize: '16' }}>B</text>
            </svg>
          </td>
          <td>{this.props.title.slice(0, 50)}</td>
          <td>{this.props.artist}</td>
          <td>{this.props.genre}</td>
          <td>{this.props.mood}</td>
          <td>{SecondsToTime(this.props.duration)}</td>
        </tr>
      )
    }
}

export default TrackRow;