import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import MixerChannel from 'components/MixerChannel';

import 'bootstrap/dist/css/bootstrap.css';
import 'index.css';
import * as Tone from 'tone';


class Mixer extends React.Component {
  constructor(props) {
    super(props);

    this.gainRampTime = 0.01;

    const masterChannelSplitter = new Tone.Split(2);
    const channelMerger = new Tone.Merge(Tone.Destination.maxChannelCount >= 4 ? 4 : 2);

    if (Tone.Destination.maxChannelCount >= 4) {
      Tone.Destination.channelCount = 4;
      this.headphonesLimiter = new Tone.Limiter(props.limiterHeadroom);
      const headphonesChannelSplitter = new Tone.Split(2);
      this.headphonesLimiter.connect(headphonesChannelSplitter);
      headphonesChannelSplitter.connect(channelMerger, 0, 2);
      headphonesChannelSplitter.connect(channelMerger, 1, 3);
    }

    this.gainDeckA = new Tone.Gain(0);
    this.gainDeckB = new Tone.Gain(0);

    this.limiter = new Tone.Limiter(props.limiterHeadroom);

    this.gainDeckA.connect(this.limiter);
    this.gainDeckB.connect(this.limiter);

    this.limiter.connect(masterChannelSplitter);

    masterChannelSplitter.connect(channelMerger, 0, 0);
    masterChannelSplitter.connect(channelMerger, 1, 1);

    channelMerger.connect(Tone.Destination);

    this.upFaderA = 0;
    this.upFaderB = 0;
    this.crossFader = 0;

    this.limiter.connect(this.props.masterMeter);

    this.state = {
      crossFaderPosition: 0,
    }
  }  
  
  componentDidUpdate(prevProps) {
    if (this.props.limiterHeadroom !== prevProps.limiterHeadroom) {
      this.limiter.threshold.rampTo(this.props.limiterHeadroom);
    }
  }

  calculateGain = (deckName, crossFaderValue) => {
    if (deckName === "A")
    {
      if (crossFaderValue <= 0) {
        return this.upFaderA;
      } else {
        return (1 - parseFloat(crossFaderValue)) * this.upFaderA;
      }
    } else {
      if (crossFaderValue >= 0) {
        return this.upFaderB;
      } else { 
        return (1 + parseFloat(crossFaderValue)) * this.upFaderB;
      }
    }
  }

  onUpFaderChange = (deckName, gainValue) =>
  {
    if (deckName === "A")
    {
      this.upFaderA = gainValue;
      this.gainDeckA.gain.cancelScheduledValues();
      this.gainDeckA.gain.rampTo(this.calculateGain(deckName, this.crossFader), this.gainRampTime);
    }
    else 
    {
      this.upFaderB = gainValue;
      this.gainDeckB.gain.cancelScheduledValues();
      this.gainDeckB.gain.rampTo(this.calculateGain(deckName, this.crossFader), this.gainRampTime);
    }
  }

  onCrossFaderChange = (value) => {
    this.setState({crossFaderPosition: value}); 
    this.crossFader = value;
    this.gainDeckA.gain.cancelScheduledValues();
    this.gainDeckA.gain.rampTo(this.calculateGain("A", this.crossFader), this.gainRampTime);

    this.gainDeckB.gain.cancelScheduledValues();
    this.gainDeckB.gain.rampTo(this.calculateGain("B", this.crossFader), this.gainRampTime);
  }

  onCrossFaderClick = (e) => {
    switch (e.detail) {
      case 2:
        this.onCrossFaderChange(0);
        break;
      default:
        return;
    }
  }

  render() {
      return (
        <div className="border border-dark p-3">
            <Row>
              <Col className="ps-2 pe-2">
                <MixerChannel channelName="A" inputNode={this.props.inputNodes[0]} outputNodes={Tone.Destination.maxChannelCount >=4 ? [this.gainDeckA, this.headphonesLimiter] : [this.gainDeckA]} mixerSide="Left" onUpFaderChange={this.onUpFaderChange}/>
              </Col>
              <Col className="ps-2 pe-2">
                <MixerChannel channelName="B" inputNode={this.props.inputNodes[1]} outputNodes={Tone.Destination.maxChannelCount >=4 ? [this.gainDeckB, this.headphonesLimiter] : [this.gainDeckB]} mixerSide="Right"onUpFaderChange={this.onUpFaderChange}/>
              </Col>
            </Row>
            <Row>
              <Col className="pb-3" align="center"><input style={{height: "50px"}} className="crossfader" type="range" id="crossFader" min="-1" max="1" value={this.state.crossFaderPosition} step="0.01" onClick={(e) => this.onCrossFaderClick(e)} onChange={(e) => this.onCrossFaderChange(e.target.value)}/></Col>
            </Row>
        </div>
      )
    }
  }

export default Mixer;