import React, { useState } from 'react';
import 'index.css';

function RestartButton(props) {
    const [isActive, setIsActive] = useState(false);

    return (
        <svg width={"30px"} height={"50px"} className={"border-end border-dark"} onMouseDown={() => {setIsActive(true); props.setCurrentTime(0);}} onMouseUp={() => setIsActive(false)}>
            <rect fill={isActive ? "#1f1f1f" : "#000000"} width="30" height="50" x={0}/>
            <text x={"50%"} y="30" textAnchor="middle" style={{ userSelect: 'none', fill: 'white', fontSize: '12' }}>{"<"}</text>
        </svg>  
    );
}

export default RestartButton;