import React from 'react';
import { Knob, Pointer, Range, Scale } from 'rc-knob';

let baseSize = 70;

class MixerKnob extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        value: 50
      };
    }

    render() {
      return (
      <Knob 
        size={this.props.size}  
        angleOffset={220} 
        angleRange={280}
        min={0}
        max={100}
        value={this.state.value}
        onChange={(value) => { this.setState({value: value}); this.props.onChange((value - 50) / 50)}}
        className="eqknob mb-3"
        useMouseWheel={false}
      >
        <defs>
          <linearGradient id="knobGradient" gradientTransform="rotate(90)">
            <stop offset="5%"  stopColor="#3f3f3f" />
            <stop offset="95%" stopColor="#202020" />
          </linearGradient>
        </defs>
        <circle r="36%" cx="50%" cy="50%" fill="black"/>
        <circle r="28%" cx="50%" cy="50%" fill="url('#knobGradient')"/>
        <circle r="21%" cx="50%" cy="50%" fill="#4f4f4f"/>
        <Pointer 
          width={this.props.size >= 50 ? 2 : 1}
          height={this.props.size * 12 / baseSize}
          radius={this.props.size * 3 / baseSize}
          type="rect"
          color="#fff"
        />
        <Range
          arcWidth={this.props.size * 2.5 / baseSize}
          radius={this.props.size * 23.75 / baseSize}
          color="#0dcaf0"
          percentageTo={0.5}
          />
          <Scale
            steps={12}
            tickWidth={1}
            tickHeight={this.props.size * 4 / baseSize}
            radius={this.props.size * 32.5 / baseSize}
            color="grey"
          />
          <Scale
            steps={2}
            tickWidth={1}
            tickHeight={this.props.size * 4 / baseSize}
            radius={this.props.size * 32.5 / baseSize}
            color="lightgrey"
          />
          <text style={{ userSelect: 'none', fill: 'white', fontSize: (this.props.size * 10 / baseSize) }} y={this.props.size} x="50%" textAnchor="middle">
          {this.props.label}
      </text>
      </Knob>
      );
    }
}

export default MixerKnob;