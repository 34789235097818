import React, { useState } from 'react';
import { Stack } from 'react-bootstrap';
import 'index.css';

function ZoomControls(props) {
    const [isZoomInActive, setIsZoomInActive] = useState(false);
    const [isZoomOutActive, setIsZoomOutActive] = useState(false);

    return (
        <Stack>
            <svg width={"30px"} height={"100px"} className={"border-end border-bottom border-dark"} onMouseDown={() => {setIsZoomInActive(true); props.zoomIn();}} onMouseUp={() => setIsZoomInActive(false)}>
                <rect fill={isZoomInActive ? "#1f1f1f" : "#000000"} width="30" height="100" x={0}/>
                <text x={"50%"} y="60" textAnchor="middle" style={{ userSelect: 'none', fill: 'white', fontSize: '12' }}>{"+"}</text>
            </svg>
            <svg width={"30px"} height={"100px"} className={"border-end border-bottom border-dark"} onMouseDown={() => {setIsZoomOutActive(true); props.zoomOut();}} onMouseUp={() => setIsZoomOutActive(false)}>
                <rect fill={isZoomOutActive ? "#1f1f1f" : "#000000"} width="30" height="100" x={0}/>
                <text x={"50%"} y="60" textAnchor="middle" style={{ userSelect: 'none', fill: 'white', fontSize: '12' }}>{"--"}</text>
            </svg>
        </Stack> 
    );
}

export default ZoomControls;