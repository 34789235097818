export function SecondsToTime(e) {
  if (isNaN(e))
  {
    return "00:00"
  }  
  
  var m = Math.floor(e % 3600 / 60).toString().padStart(2,'0'),
        s = Math.floor(e % 60).toString().padStart(2,'0');
    
    return m + ':' + s;
}

export function GetFormattedKey(classicalKey, format, transposition)
{
  if (!classicalKey) {
    return null;
  }

  let KeyList = classicalKey.includes('major') ? MajorKeys : MinorKeys;

  let keyIndex = 0;
  if (classicalKey.includes('#')){
    keyIndex = KeyList.findIndex(key => key.Classical_Sharps === classicalKey);
  } else {
    keyIndex = KeyList.findIndex(key => key.Classical === classicalKey);
  }

  keyIndex = (keyIndex + parseInt(transposition)) % KeyList.length;
  if (keyIndex < 0) {
    keyIndex = KeyList.length + keyIndex;
  }

  let key = KeyList[keyIndex];

  if (format === "Camelot")
  {
    return {Key: key.Camelot, Color: key.Color};
  } else if (format === "OpenKey") {
    return {Key: key.OpenKey, Color: key.Color};
  } else {
    return {Key: classicalKey, Color: key.Color};
  }
}

export function GetNearestGridMarkerPosition(time, bpm, downbeatPosition) {
  if (bpm === 0) {return 0;}
  
  const downbeatAdjustment = TimeToBeats(downbeatPosition, bpm) % 1;
  // if (downbeatAdjustment >= 0.5) {
  //   downbeatAdjustment = downbeatAdjustment - 1;
  // }
  const snapPosition = BeatsToTime(Math.round(TimeToBeats(time, bpm) - downbeatAdjustment) + downbeatAdjustment, bpm);
  return Math.trunc(snapPosition * 1000000) / 1000000;
}

export function convertTimeToWaveFormPosition(time, duration, waveFormWidth = 450) {
  return duration === 0 ? 0 : time / duration * waveFormWidth;
}

export function TimeToBeats(time, bpm) {
  return time * bpm / 60
}

export function BeatsToTime(beats, bpm) {
  return beats * 60 / bpm
}

export function ArrayBufferToBase64( buffer ) {
  var binary = '';
  var bytes = new Uint8Array( buffer );
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
      binary += String.fromCharCode( bytes[ i ] );
  }
  return window.btoa( binary );
}

export function NormalizeBpmToRange(bpm, range) {
  const low = range[0];
  const high = range[1];

  while (bpm < low) {
    bpm *= 2;
  }

  while (bpm > high) {
    bpm /= 2;
  }

  return bpm;
}

const MinorKeys = [
  {
    Camelot: "1A",
    Classical: "Ab minor",
    Classical_Sharps: "G# minor",
    OpenKey: "6m",
    Color: "#70c4a0"
  },
  {
    Camelot: "2A",
    Classical: "Eb minor",
    Classical_Sharps: "D# minor",
    OpenKey: "7m",
    Color: "#8fc555"
  },
  {
    Camelot: "3A",
    Classical: "Bb minor",
    Classical_Sharps: "A# minor",
    OpenKey: "8m",
    Color: "#b2d145"
  },
  {
    Camelot: "4A",
    Classical: "F minor",
    OpenKey: "9m",
    Color: "#fcdf45"
  },
  {
    Camelot: "5A",
    Classical: "C minor",
    OpenKey: "10m",
    Color: "#f5a158"
  },
  {
    Camelot: "6A",
    Classical: "G minor",
    OpenKey: "11m",
    Color: "#f1855f"
  },
  {
    Camelot: "7A",
    Classical: "D minor",
    OpenKey: "12m",
    Color: "#ed7171"
  },
  {
    Camelot: "8A",
    Classical: "A minor",
    OpenKey: "1m",
    Color: "#c680b6"
  },
  {
    Camelot: "9A",
    Classical: "E minor",
    OpenKey: "2m",
    Color: "#9b86be"
  },
  {
    Camelot: "10A",
    Classical: "B minor",
    OpenKey: "3m",
    Color: "#7499cd"
  },
  {
    Camelot: "11A",
    Classical: "Gb minor",
    Classical_Sharps: "F# minor",
    OpenKey: "4m",
    Color: "#63cdf4"
  },
  {
    Camelot: "12A",
    Classical: "Db minor",
    Classical_Sharps: "C# minor",
    OpenKey: "5m",
    Color: "#7bcdd9"
  },
]

const MajorKeys = [
  {
    Camelot: "1B",
    Classical: "B major",
    OpenKey: "6d",
    Color: "#4cb686"
  },
  {
    Camelot: "2B",
    Classical: "Gb major",
    Classical_Sharps: "F# major",
    OpenKey: "7d",
    Color: "#73b629"
  },
  {
    Camelot: "3B",
    Classical: "Db major",
    Classical_Sharps: "C# major",
    OpenKey: "8d",
    Color: "#9fc516"
  },
  {
    Camelot: "4B",
    Classical: "Ab major",
    Classical_Sharps: "G# major",
    OpenKey: "9d",
    Color: "#fdd615"
  },
  {
    Camelot: "5B",
    Classical: "Eb major",
    Classical_Sharps: "D# major",
    OpenKey: "10d",
    Color: "#f28b2e"
  },
  {
    Camelot: "6B",
    Classical: "Bb major",
    Classical_Sharps: "A# major",
    OpenKey: "11d",
    Color: "#ec6637"
  },
  {
    Camelot: "7B",
    Classical: "F major",
    OpenKey: "12d",
    Color: "#e84c4d"
  },
  {
    Camelot: "8B",
    Classical: "C major",
    OpenKey: "1d",
    Color: "#b960a2"
  },
  {
    Camelot: "9B",
    Classical: "G major",
    OpenKey: "2d",
    Color: "#8269ab"
  },
  {
    Camelot: "10B",
    Classical: "D major",
    OpenKey: "3d",
    Color: "#527fc0"
  },
  {
    Camelot: "11B",
    Classical: "A major",
    OpenKey: "4d",
    Color: "#3cc0ef"
  },
  {
    Camelot: "12B",
    Classical: "E major",
    OpenKey: "5d",
    Color: "#5bc1ce"
  }
];