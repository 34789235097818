import React from 'react';
import * as Tone from 'tone';

class ChannelMeter extends React.Component {
    constructor(props) {
      super(props);
      this.canvasRef = React.createRef();
      this.clock = null;
      this.canvasContext = null;
    }
    
    renderMeter = (height) => {
      this.canvasContext.clearRect(0, 0, 5, 160);
      this.canvasContext.fillStyle = '#12c9f0';
      this.canvasContext.fillRect(0, 160, 10, -1 * Math.min(height, 30) * (160 / 42));
  
      if (height > 30)
      {
        this.canvasContext.fillStyle = '#ffa652';
        this.canvasContext.fillRect(0, 160 - (160 / 42 * 30), 5, -1 * (height - 30) * (160 / 42));
      }
  
      // Over 0 should be clipping, but should add a limiter / headroom probably...
      // this.canvasContext.fillStyle = 'red';
      // this.canvasContext.fillRect(0, 200, 10, -1 * height);
    }
  
    componentDidMount()
    {
      this.canvasContext = this.canvasRef.current.getContext('2d');
      this.clock = new Tone.Clock((time) => {
        this.renderMeter(this.props.meter.getValue() + 42);
      }, 24);
      this.clock.start();
    }
  
    render()
    {
      return (
        <canvas width="5px" height="160px" className="border border-dark" ref={this.canvasRef} style={{backgroundColor: "black"}}/>
      )
    }
  }

export default ChannelMeter;