import React from 'react';
import { Row, Col, Spinner, Stack } from 'react-bootstrap';

import { SecondsToTime, GetFormattedKey } from 'Utilities'
import TrackInfo from 'components/TrackInfo';
import TrackModifiers from 'components/TrackModifiers';
import D3WaveFormOverview from 'components/D3WaveFormOverview';
import D3WaveForm from 'components/D3WaveForm';
import HotCues from 'components/HotCues';
import CueButton from 'components/CueButton'
import PlayButton from 'components/PlayButton'
import BeatJump from 'components/BeatJump'
import LengthSelector from 'components/LengthSelector';
import GridOptions from 'components/GridOptions';
import RestartButton from 'components/RestartButton';
import TimeDisplay from 'components/TimeDisplay';

import TempoControls from 'components/TempoControls';
import { ArrayBufferToBase64, GetNearestGridMarkerPosition, BeatsToTime, NormalizeBpmToRange } from 'Utilities';

import * as Tone from 'tone';
import 'bootstrap/dist/css/bootstrap.css';
import 'index.css';

class Deck extends React.Component {
  constructor(props) {
    super(props);
  
    this.clock = null;
    this.loopClock = null;
    this.isLoopActive = false;
    this.loopStart = 0;
    this.loopScheduled = false;
    this.state = {
      isPlaying: false,
      isLoading: false,
      tempoChangePercentage: 0,
      keyLockChecked: true,
      playheadPosition: 0,
      loopLength: 4,
      loopStart: 0,
      isLoopActive: false,
      snapToGrid: true,
      cuePosition: 0,
      hotCues: [],
      downbeatPosition: 0,
      currentBpm: 0,
      tempoRange: 10,
      track: {
        Id: "",
        Name: "",
        Artist: "",
        Genre: "",
        ArtworkSrc: "https://via.placeholder.com/150/3f3f3f/3f3f3f?text=.",
        Duration: 0,
        Key: null,
        Bpm: 0,
        WaveformPaths: ""
      }
    };
  }  
  
  componentDidMount() {
    this.clock = new Tone.Clock((time) => {
      this.setState({playheadPosition: this.state.track.Duration === 0 ? 0 : this.props.audio.currentTime / this.state.track.Duration * 450});
    }, 30);
    this.clock.start();

    this.loopClock = new Tone.Clock((time) => {
      Tone.getContext().lookAhead = 0.1;
      Tone.getContext().updateInterval= 0.005;
      const lookahead = 0.1;
      const playheadTime = parseFloat(this.props.audio.currentTime);
      const loopEnd = this.loopStart + BeatsToTime(this.state.loopLength, this.state.currentBpm);
      if (!this.loopScheduled && playheadTime + lookahead >= loopEnd) {
        this.loopScheduled = true;
        Tone.context.setTimeout(() => {
          this.props.setCurrentTime(this.loopStart);
          this.loopScheduled = false;
        }, loopEnd - playheadTime)
      }
    }, 100);
  }

  componentDidUpdate(prevProps) {
    if (this.props.trackData.id !== prevProps.trackData.id) {
      this.loadDeck(this.props.trackData.id, this.props.trackData.title, this.props.trackData.artist, this.props.trackData.genre, this.props.trackData.artwork);
    }
  }

  componentWillUnmount() {
    this.clock.stop();
    this.clock.dispose();
    this.clock = null;

    this.loopClock.stop();
    this.loopClock.dispose();
    this.loopClock = null;
  }

  onDeckDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer.types.includes("track/id"))
    {
      this.loadDeck(e.dataTransfer.getData("track/id"), e.dataTransfer.getData("track/title"), e.dataTransfer.getData("track/artist"), e.dataTransfer.getData("track/genre"), e.dataTransfer.getData("track/artwork"));
    }
  }

  resetDeck = () => {
    this.setState({
      isPlaying: false,
      currentBpm: this.state.track.Bpm,
      downbeatPosition: 0,
      cuePosition: 0, 
      hotCues: [],
      loopStart: 0,
      isLoopActive: false});

      this.isLoopActive = false;
      this.loopClock.stop();
      this.loopStart = 0;
  }

  playDeck = () => {
    this.props.play();
    this.setState({isPlaying: true});
  }

  pauseDeck = () => {
    this.props.pause();
    this.setState({isPlaying: false});
  }

  loadDeck = (trackId, trackName, trackArtist, trackGenre, artwork) =>
  {
    this.setState({isLoading: true});
    this.props.initializeTone().then(() => {
      const bufferTrackPromise = this.props.audiusClient.StreamTrack(trackId).then((axiosResponse) => {
        this.props.setOnCanPlayThrough(() =>
        {
          this.setState((state) => ({
            track: {
              ...state.track,
              Id: trackId, 
              Duration: this.props.audio.duration, 
              Name: trackName, 
              Artist: trackArtist, 
              Genre: trackGenre, 
              ArtworkSrc: artwork}}));
        });
        this.props.setOnEnded(() => {this.setState({isPlaying: false})}); 
        this.props.setSrc("data:audio/mpeg;base64," + ArrayBufferToBase64(axiosResponse.data));
      },
      (error) => {this.props.setError(error)});
      const analyzeTrackPromise= this.props.queryApollo(trackId).then((apolloResponse) => {
        this.setState((state) => ({
          track: {
            ...state.track,
            Key: apolloResponse.data.track.key, 
            Bpm: NormalizeBpmToRange(Math.round(apolloResponse.data.track.bpm), this.props.bpmAnalysisRange), 
            WaveformPaths: apolloResponse.data.track.waveform}}));
      },
      (error) => {this.props.setError(error);});

      Promise.allSettled([bufferTrackPromise, analyzeTrackPromise]).then((r) => {this.resetDeck(); this.setState({isLoading: false});});
    })
  }

  onCueDown = () => {
    if (this.state.isPlaying)
    {
      this.pauseDeck();
      this.props.setCurrentTime(this.state.cuePosition)
    }
    else {
      if (this.props.audio.currentTime !== this.state.cuePosition)
      {
        if (this.state.snapToGrid) {
          const snappedPosition = GetNearestGridMarkerPosition(parseFloat(this.props.audio.currentTime), this.state.currentBpm, this.state.downbeatPosition);
          this.setState({cuePosition: snappedPosition});
          this.props.setCurrentTime(snappedPosition);
        }
        else {
          this.setState({cuePosition: this.props.audio.currentTime});
        }
        
      }
      else {
        this.playDeck();
      }
    }
  }

  onCueUp = () => {
    if (this.state.isPlaying)
    {
      this.pauseDeck();
      this.props.setCurrentTime(this.state.cuePosition);
    }
  }

  setTempoChangePercentage = (value) => {
    this.setState({tempoChangePercentage: value});
    const calculatedPlayBackRate = 1 + value;
    this.props.setPlaybackRate(calculatedPlayBackRate);
  }

  setLoopLength = (length) => {
    this.setState({loopLength: length});
  }

  setHotCue = (id, position) => {
    this.setState((state) => {
      return ({hotCues: [...state.hotCues, {id: id, position: position}]});
    });
  }

  clearHotCue = (id) => {
    this.setState((state) => {
      return ({hotCues: state.hotCues.filter((hc) => hc.id !== id)});
    });
  }

  toggleSnapToGrid = () => {
    this.setState((state) => ({snapToGrid: !state.snapToGrid}));
  }

  increaseTempoRange = () => {
    this.setState((state) => {
      if (state.tempoRange >= 20) {
        return {tempoRange: 20};
      }
      else {
        return {tempoRange: state.tempoRange + 2};
      }
    });
  }

  decreaseTempoRange = () => {
    this.setState((state) => {
      if (state.tempoRange <= 2) {
        return {tempoRange: 2};
      }
      else {
        return {tempoRange: state.tempoRange - 2};
      }
    });
  }

  toggleLoopActive = () => {
    if (this.props.audio.currentSrc) {
      if (this.state.snapToGrid) {
        const snappedPosition = GetNearestGridMarkerPosition(parseFloat(this.props.audio.currentTime), this.state.currentBpm, this.state.downbeatPosition);
        this.setState({loopStart: snappedPosition})
        this.loopStart = snappedPosition;
        if (!this.state.isPlaying && !this.isLoopActive) {
          this.props.setCurrentTime(snappedPosition);
        }
      }
      else {
        const position = parseFloat(this.props.audio.currentTime);
        this.setState({loopStart: position});
        this.loopStart = position;
      }

      this.setState((state) => ({isLoopActive: !state.isLoopActive}));
      this.isLoopActive = !this.isLoopActive;
  
      if (!this.isLoopActive) {
        this.loopClock.stop();
      } else if (this.props.audio.currentTime <= this.loopStart + BeatsToTime(this.state.loopLength, this.state.currentBpm)) {
        this.loopClock.start();
      }
    }
  }

  render() {
     if (this.state.isLoading) {
      return (
        <Col onDrop={(e) => this.onDeckDrop(e)} onDragOver={(e) => e.preventDefault()}>
          <div className="border border-dark" style={{height: "453px"}}>
            <Row className="g-0">
              <Col className="m-1"><h2 className="float-end m-3">{this.props.deckName}</h2></Col>
            </Row>
            <Row className='m-5'>
              <Col align="center"><Spinner animation="border"/><h5>Analyzing</h5></Col>
            </Row>
          </div>
        </Col>
      )
     } else {
      return (
        <Col onDrop={(e) => this.onDeckDrop(e)} onDragOver={(e) => e.preventDefault()}>
          <Row className="g-0">
            <Col>
              <TrackInfo className="m-1" title={this.state.track.Name} artist={this.state.track.Artist} artworkSrc={this.state.track.ArtworkSrc}/>
            </Col>
            <Col xs="auto">
              <Stack className="float-end m-1" direction="horizontal" gap={4}>
                <TrackModifiers overlay={<GridOptions snapToGrid={this.state.snapToGrid} toggleSnapToGrid={this.toggleSnapToGrid} setBpm={(value) => this.setState({currentBpm: value})} trackBpm={this.state.currentBpm} initialBpm={this.state.track.Bpm} setDownbeatPosition={(value) => this.setState({downbeatPosition: value})} downbeatPosition={this.state.downbeatPosition} currentTime={this.props.audio.currentTime}/>} currentTime={() => this.props.audio.currentTime} totalTime={this.state.track.Duration} bpm={this.state.currentBpm} camelotKey={GetFormattedKey(this.state.track.Key, this.props.keyFormat, 0) ?? ""} tempoChangePercentage={this.state.tempoChangePercentage} setTempoChangePercentage={this.setTempoChangePercentage} toggleKeyLock={this.props.toggleKeyLock}/>
                <h2 className="m-3" style={{ userSelect: 'none'}}>{this.props.deckName}</h2>
              </Stack>
            </Col>
          </Row>
          <Row className="g-0">
            <D3WaveForm width="100%" height={"200px"} deckName={this.props.deckName} waveformPaths={this.state.track.WaveformPaths} size={[450,130]} cuePosition={this.state.track.Duration === 0 ? 0 : this.state.cuePosition / this.state.track.Duration * 450} playheadPosition={this.state.playheadPosition} bpm={this.state.currentBpm} duration={this.state.track.Duration} downbeatPosition={this.state.track.Duration === 0 ? 0 : this.state.downbeatPosition / this.state.track.Duration * 450} shiftPlayhead={(t) => this.props.shiftCurrentTime(t)} hotCues={this.state.hotCues} loopStart={this.state.loopStart} loopEnd={this.state.loopStart + BeatsToTime(this.state.loopLength, this.state.currentBpm)} loopActive={this.state.isLoopActive} play={this.props.play} pause={this.props.pause} isPlaying={this.state.isPlaying}/>
            <Col xs="auto">
              <TempoControls tempoRange={this.state.tempoRange} decreaseTempoRange={this.decreaseTempoRange} increaseTempoRange={this.increaseTempoRange} tempoChangePercentage={this.state.tempoChangePercentage} setTempoChangePercentage={this.setTempoChangePercentage}/>
            </Col>
          </Row>
          <Row className="g-0">
            <Col xs="auto"><RestartButton setCurrentTime={this.props.setCurrentTime} /></Col>
            <Col><D3WaveFormOverview width="100%" height="50px" size={[450,130]} showWarning={this.state.isPlaying && this.state.track.Duration - this.props.audio.currentTime <= 30.0} duration={this.state.track.Duration} waveformPaths={this.state.track.WaveformPaths} cuePosition={this.state.track.Duration === 0 ? 0 : this.state.cuePosition / this.state.track.Duration * 450} playheadPosition={this.state.playheadPosition} setPlayhead={(xPos) => this.props.setCurrentTime(Math.min(Math.max((xPos / 450), 0), this.state.track.Duration) * this.state.track.Duration)} hotCues={this.state.hotCues} loopStart={this.state.loopStart} loopEnd={this.state.loopStart + BeatsToTime(this.state.loopLength, this.state.currentBpm)} loopActive={this.state.isLoopActive}/></Col>
            <Col xs="auto"><TimeDisplay value={"-" + SecondsToTime(this.state.track.Duration - this.props.audio.currentTime)}/></Col>
          </Row>
          <HotCues snap={(position) => this.state.snapToGrid ? GetNearestGridMarkerPosition(position, this.state.currentBpm, this.state.downbeatPosition) : position} currentTime={this.props.audio.currentTime} setCurrentTime={this.props.setCurrentTime} setHotCue={this.setHotCue} clearHotCue={this.clearHotCue} isPlaying={this.state.isPlaying} Play={this.playDeck} Pause={this.pauseDeck}/>
          <Row>
            <Col>
              <Stack className="m-2" direction="horizontal" gap={2}>
                <PlayButton isPlaying={this.state.isPlaying} Play={this.playDeck} Pause={this.pauseDeck}/>
                <CueButton onCueDown={this.onCueDown} onCueUp={this.onCueUp}/>
              </Stack>
            </Col>
            <Col xs="auto">
              <Stack className="float-end m-2" direction="horizontal" gap={4}>
                <BeatJump bpm={this.state.currentBpm} length={this.state.loopLength} shiftCurrentTime={(t) => this.props.shiftCurrentTime(t)}/>
                <LengthSelector length={this.state.loopLength} setLoopLength={this.setLoopLength} toggleLoopActive={this.toggleLoopActive} isLoopActive={this.state.isLoopActive}/>
              </Stack>
            </Col>
          </Row>
        </Col>
      )
     }
    }
}

export default Deck;