import React from 'react';
import { Row, Col, Navbar, Nav, Offcanvas, Form } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.css';
import 'styles/Navigation.css'

class Navigation extends React.Component {    
    render() {
      return (
        <Navbar className="navbar-beatmatch" expand={false}>
          <Navbar.Brand href="#">
            <p className="navbar-brand-beatmatch">beatmatch.xyz</p>
          </Navbar.Brand>
          <div style={{position: "absolute", left: "50%", transform: "translatex(-50%)"}}>{this.props.masterMeter}</div>
          <Navbar.Toggle aria-controls="offcanvasNavbar">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" viewBox="0 0 20 20">
              <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/>
            </svg>
          </Navbar.Toggle>
          <Navbar.Offcanvas id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" placement="end">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel" className="navbar-offcanvas-header-title-beatmatch">Settings</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Form className='p-2'>
                <Form.Group as={Row} className="mb-3" controlId="formKeyDisplayFormat">
                  <Form.Label column sm="5" className="navbar-offcanvas-body-form-label-beatmatch">
                    Key Format
                  </Form.Label>
                  <Col sm="7">
                    <Form.Select aria-label="KeyDisplayFormat" onChange={(e) => this.props.onKeyFormatChange(e.target.value)}>
                      <option value="Camelot">Camelot</option>
                      <option value="Classical">Classical</option>
                      <option value="OpenKey">Open Key</option>
                    </Form.Select>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formBpmAnalysisRange">
                  <Form.Label column sm="5" className="navbar-offcanvas-body-form-label-beatmatch">
                    BPM Analysis Range
                  </Form.Label>
                  <Col sm="7">
                    <Form.Select aria-label="BpmAnalysisRange" defaultValue={"88-175"} onChange={(e) => this.props.onBpmAnalysisRangeChange(e.target.value)}>
                      <option value="48-95">48-95</option>
                      <option value="58-115">58-115</option>
                      <option value="68-135">68-135</option>
                      <option value="78-155">78-155</option>
                      <option value="88-175">88-175</option>
                      <option value="98-195">98-195</option>
                      <option value="108-215">108-215</option>
                      <option value="118-235">118-235</option>
                      <option value="128-255">128-255</option>
                    </Form.Select>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formLimiterHeadroom">
                  <Form.Label column sm="5" className="navbar-offcanvas-body-form-label-beatmatch">
                    Limiter Threshold
                  </Form.Label>
                  <Col sm="7">
                    <Form.Select aria-label="LimiterHeadroom" defaultValue={-3} onChange={(e) => this.props.onLimiterHeadroomChange(parseInt(e.target.value))}>
                      <option value={-3}>-3 dB</option>
                      <option value={-6}>-6 dB</option>
                      <option value={-9}>-9 dB</option>
                      <option value={-12}>-12 dB</option>
                    </Form.Select>
                  </Col>
                </Form.Group>
              </Form>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Navbar>
      )
    }
}

export default Navigation;