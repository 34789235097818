import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MixerKnob from 'components/MixerKnob';

import 'bootstrap/dist/css/bootstrap.css';
import 'index.css';

class ChannelEQ extends React.Component {
    constructor(props) {
        super(props);
        this.eqRampTime = 0.005;
    }


    handleChange = (eqType, value) => {
        const scaledValue = value >= 0 ? parseFloat(value) * 6 : parseFloat(value) * 26;

        if (eqType === "Lo") {
            this.props.eq.low.cancelScheduledValues();
            this.props.eq.low.rampTo(scaledValue, this.eqRampTime);
        } else if (eqType === "Mid") {
            this.props.eq.mid.cancelScheduledValues();
            this.props.eq.mid.rampTo(scaledValue, this.eqRampTime);
        } else if (eqType === "Hi") {
            this.props.eq.high.cancelScheduledValues();
            this.props.eq.high.rampTo(scaledValue, this.eqRampTime);
        }
    }
    
    render() {
      return (
        <>
            <Row>
                <Col><MixerKnob size={60} label="Hi" onChange={(value) => this.handleChange("Hi", value)}></MixerKnob></Col>
            </Row>
            <Row>
                <Col><MixerKnob size={60} label="Mid" onChange={(value) => this.handleChange("Mid", value)}></MixerKnob></Col>
            </Row>
            <Row>
                <Col><MixerKnob size={60} label="Lo" onChange={(value) => this.handleChange("Lo", value)}></MixerKnob></Col>
            </Row>
        </>
      )
    }
  }

export default ChannelEQ;