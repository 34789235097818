import React from 'react';
import Stack from 'react-bootstrap/Stack'

class LengthSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDecreaseLengthActive: false,
            isIncreaseLengthActive: false,
        }
    }

    increaseLength = () =>
    {
        let length = this.props.length;
        if (length < 32){
            length = length * 2;
        }

        this.props.setLoopLength(length);
    }

    decreaseLength = () => {
        let length = this.props.length;
        if (length > 1/32){
            length = length / 2;
        }

        this.props.setLoopLength(length);
    }

    formatLength = (length) => {
        if (length < 1) {
            return `1/${(1 / length)}`;
        }
        return length;
    }

    render() {
      return (
        <Stack direction="horizontal" gap={0}>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill={"black"} onMouseDown={() => {this.setState({isDecreaseLengthActive: true}); this.decreaseLength();}} onMouseUp={() => {this.setState({isDecreaseLengthActive: false});}}>
                <rect fill={this.state.isDecreaseLengthActive ? "0f0f0f" : "#1f1f1f"} stroke={"#0f0f0f"} strokeWidth={1} width="40" height="40" x={0}/>
                <text x={"50%"} y="28" textAnchor="middle" style={{ userSelect: 'none', fill: 'white', fontSize: '18' }}>{"<"}</text>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="40" fill={"black"} onMouseDown={() => {this.props.toggleLoopActive();}}>
                <rect fill="#0f0f0f" stroke={"#0f0f0f"} strokeWidth={1} width="50" height="40" x={0}/>
                <rect display={this.props.isLoopActive ? "" : "none"} fill="green" fillOpacity="50%" stroke={"#0f0f0f"} strokeWidth={1} width="50" height="40" />
                <text x={"50%"} y="28" textAnchor="middle" style={{ userSelect: 'none', fill: 'white', fontSize: '18' }}>{this.formatLength(this.props.length)}</text>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill={"black"} onMouseDown={() => {this.setState({isIncreaseLengthActive: true}); this.increaseLength();}} onMouseUp={() => {this.setState({isIncreaseLengthActive: false});}}>
                <rect fill={this.state.isIncreaseLengthActive ? "0f0f0f" : "#1f1f1f"} stroke={"#0f0f0f"} strokeWidth={1} width="40" height="40" x={0}/>
                <text x={"50%"} y="28" textAnchor="middle" style={{ userSelect: 'none', fill: 'white', fontSize: '18' }}>{">"}</text>
            </svg>
        </Stack>
      );
    }
}

export default LengthSelector;