import React from 'react';
import { Stack } from 'react-bootstrap'
import TempoNudge from 'components/TempoNudge';
import KeyDisplay from 'components/KeyDisplay';
import TempoDisplay from 'components/TempoDisplay';
import 'bootstrap/dist/css/bootstrap.css';
import 'index.css';

class TrackModifiers extends React.Component {
  render() {
      return (
          <Stack>
            <TempoNudge tempoChangePercentage={this.props.tempoChangePercentage} setTempoChangePercentage={this.props.setTempoChangePercentage} />
            <Stack direction="horizontal" gap={1}>
              <KeyDisplay toggleKeyLock={this.props.toggleKeyLock} camelotKey={this.props.camelotKey}/>
              <TempoDisplay overlay={this.props.overlay} tempoChangePercentage={this.props.tempoChangePercentage} bpm={this.props.bpm}/>              
            </Stack>
          </Stack>
      )
    }
}

export default TrackModifiers;