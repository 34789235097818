import React from 'react';
import Stack from 'react-bootstrap/Stack'

class BeatJump extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isJumpLeftActive: false,
            isJumpRightActive: false
        }
    }

    beatJump = (direction) => {
        let shiftAmountInSeconds = this.props.length * (60 / this.props.bpm);

        if (direction === "Right") {
            shiftAmountInSeconds *= -1;
        }

        this.props.shiftCurrentTime(shiftAmountInSeconds);
    }

    render() {
      return (
        <Stack direction="horizontal" gap={0}>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill={"black"} onMouseDown={() => {this.setState({isJumpLeftActive: true}); this.beatJump("Left");}} onMouseUp={() => {this.setState({isJumpLeftActive: false});}}>
                <rect fill={this.state.isJumpLeftActive ? "0f0f0f" : "#1f1f1f"} stroke={"#0f0f0f"} strokeWidth={1} width="40" height="40" x={0}/>
                <text x={"50%"} y="28" textAnchor="middle" style={{ userSelect: 'none', fill: 'white', fontSize: '18' }}>{"<"}</text>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill={"black"} onMouseDown={() => {this.setState({isJumpRightActive: true}); this.beatJump("Right");}} onMouseUp={() => {this.setState({isJumpRightActive: false});}}>
                <rect fill={this.state.isJumpRightActive ? "0f0f0f" : "#1f1f1f"} stroke={"#0f0f0f"} strokeWidth={1} width="40" height="40" x={0}/>
                <text x={"50%"} y="28" textAnchor="middle" style={{ userSelect: 'none', fill: 'white', fontSize: '18' }}>{">"}</text>
            </svg>
        </Stack>
      );
    }
}

export default BeatJump;