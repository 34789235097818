import React from 'react';
import ReactDOM from 'react-dom';
import { Container, Row, Col, ToastContainer, Toast, Image } from 'react-bootstrap';
import { ApolloClient, InMemoryCache, gql} from "@apollo/client";
import { isDesktop, isChrome } from 'react-device-detect';
import * as Tone from 'tone';
import Mixer from 'components/Mixer';
import Deck from 'components/Deck';
import Browser from 'components/Browser'
import Navigation from 'components/Navigation'
import MasterMeter from 'components/MasterMeter'
import AudiusClient from 'AudiusClient'
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';

const axios = require('axios').default;

class DJApp extends React.Component {
  constructor(props) {
    super(props);

    this.audioDeckA = new Audio();
    this.audioDeckB = new Audio();
    this.audioNodeDeckA = Tone.context.createMediaElementSource(this.audioDeckA);
    this.audioNodeDeckB = Tone.context.createMediaElementSource(this.audioDeckB)
    this.masterMeter = new Tone.Meter({channels: 2});

    this.isToneInitialized = false;
    this.apolloClient = new ApolloClient({
      uri: 'https://09123ewdw4.execute-api.us-east-1.amazonaws.com/dev/',
      cache: new InMemoryCache()
    });

    this.audiusClient = new AudiusClient(axios, this.setError);

    this.state = {
      keyFormat: "Camelot",
      limiterHeadroom: -3,
      bpmAnalysisRange: [88, 175],
      showToast: false,
      errorDetails: "",
      trackDataDeckA: {id: null},
      trackDataDeckB: {id: null}
    };
  }

  setError = (errorDetails) => {
    this.setState({showToast: true, errorDetails: errorDetails.toString()});
  }

  initializeTone = async () => {
    if (!this.isToneInitialized) {
      await Tone.start();
      if (Tone.context.state !== 'running') {
        Tone.context.resume();
      }
      this.isToneInitialized = true;
    }
  }

  queryApollo = (trackId) => {
    return this.apolloClient
      .query({
        query: gql`
          query GetTrack($trackId: ID!) {
            track(id: $trackId) {
              id,
              bpm,
              key,
              waveform
            }
          }
        `,
        variables: {
          trackId: trackId
        }
      });
  }

  onKeyFormatChange = (keyFormat) => {
    this.setState({keyFormat: keyFormat});
  }

  onLimiterHeadroomChange = (limiterHeadroom) => {
    this.setState({limiterHeadroom: limiterHeadroom});
  }

  onBpmAnalysisRangeChange = (bpmAnalysisRange) => {
    const splitRange = bpmAnalysisRange.split('-');
    this.setState({bpmAnalysisRange: [parseInt(splitRange[0]), parseInt(splitRange[1])]});
  }

  toggleKeyLock = (audio) => {
    audio.preservesPitch = !audio.preservesPitch;
  }

  setCurrentTime = (audio, currentTime) => {
    audio.currentTime = currentTime;
  }

  shiftCurrentTime = (audio, shiftAmount) => {
    if (audio.src) {
      audio.currentTime -= shiftAmount;
    }
  }

  setPlayerPlaybackRate = (audio, value) => {
    audio.playbackRate = parseFloat(value);
  }

  play = (audio) => {
    if (audio.src) {
      audio.play()
    }
  }

  pause = (audio) => {
    if (audio.src) {
      audio.pause();
    }
  }

  setSrc = (audio, src) => {
    audio.src = src;
  }

  setOnCanPlayThough = (audio, callback) => {
    audio.oncanplaythrough = callback;
  }

  setOnEnded = (audio, callback) => {
    audio.onended = callback;
  }

  loadDeck = (deckName, id, title, artist, genre, artwork) => {
    const trackData = {
      id: id,
      title: title,
      artist: artist,
      genre: genre,
      artwork: artwork
    }
    if (deckName === "A") {
      this.setState({trackDataDeckA: trackData})
    }
    else {
      this.setState({trackDataDeckB: trackData});
    }
  }

  render() {
    if (isDesktop && isChrome) {
      return (
        <Container fluid>
          <Row>
            <Col xs={12} className="navigation-col">
              <Navigation 
                onKeyFormatChange={this.onKeyFormatChange} 
                onLimiterHeadroomChange={this.onLimiterHeadroomChange} 
                onBpmAnalysisRangeChange={this.onBpmAnalysisRangeChange}
                masterMeter={<MasterMeter meter={this.masterMeter}/>}/>
            </Col>
          </Row>
          <Row className="dj-row g-0">
            <Deck 
              deckName="A"
              initializeTone={this.initializeTone}
              audiusClient={this.audiusClient}
              audio={this.audioDeckA}
              play={() => this.play(this.audioDeckA)}
              pause={() => this.pause(this.audioDeckA)}
              setSrc={(src) => this.setSrc(this.audioDeckA, src)}
              setOnCanPlayThrough={(callback) => this.setOnCanPlayThough(this.audioDeckA, callback)}
              setOnEnded={(callback) => this.setOnEnded(this.audioDeckA, callback)}
              toggleKeyLock={() => this.toggleKeyLock(this.audioDeckA)}
              setPlaybackRate={(value) => this.setPlayerPlaybackRate(this.audioDeckA, value)}
              setCurrentTime={(time) => this.setCurrentTime(this.audioDeckA, time)}
              shiftCurrentTime={(shiftAmount) => this.shiftCurrentTime(this.audioDeckA, shiftAmount)}
              queryApollo={this.queryApollo}
              keyFormat={this.state.keyFormat}
              setError={this.setError}
              trackData={this.state.trackDataDeckA}
              bpmAnalysisRange={this.state.bpmAnalysisRange}/>
            <Col xs="auto">
              <Mixer 
                inputNodes={[this.audioNodeDeckA, this.audioNodeDeckB]} 
                limiterHeadroom={this.state.limiterHeadroom}
                masterMeter={this.masterMeter}/>
            </Col>
            <Deck 
              deckName="B"
              initializeTone={this.initializeTone}
              audiusClient={this.audiusClient}
              audio={this.audioDeckB}
              play={() => this.play(this.audioDeckB)}
              pause={() => this.pause(this.audioDeckB)}
              setSrc={(src) => this.setSrc(this.audioDeckB, src)}
              setOnCanPlayThrough={(callback) => this.setOnCanPlayThough(this.audioDeckB, callback)}
              setOnEnded={(callback) => this.setOnEnded(this.audioDeckB, callback)}
              toggleKeyLock={() => this.toggleKeyLock(this.audioDeckB)}
              setPlaybackRate={(value) => this.setPlayerPlaybackRate(this.audioDeckB, value)}
              setCurrentTime={(time) => this.setCurrentTime(this.audioDeckB, time)}
              shiftCurrentTime={(shiftAmount) => this.shiftCurrentTime(this.audioDeckB, shiftAmount)}
              queryApollo={this.queryApollo}
              keyFormat={this.state.keyFormat}
              setError={this.setError}
              trackData={this.state.trackDataDeckB}
              bpmAnalysisRange={this.state.bpmAnalysisRange}/>
          </Row>
          <Row className="browser-row g-0">
            <Col>
              <Browser onLoad={this.loadDeck} onError={this.setError} audiusClient={this.audiusClient}/>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <ToastContainer>
                <Toast bg="danger" className="position-fixed bottom-0 end-0" show={this.state.showToast} onClose={() => {this.setState({showToast: false})}}>
                  <Toast.Header>Oh No! Something Broke!</Toast.Header>
                  <Toast.Body>{this.state.errorDetails}</Toast.Body>
                </Toast>
              </ToastContainer>
            </Col>
          </Row>
        </Container>
      )
    }
    else {
      return (
        <Container fluid>
          <Row>
            <Col align="middle">
              <h3>beatmatch.xyz is currently only supported on Chrome.</h3>
              <Image style={{maxHeight: "60vh"}} fluid src={"ScreenShot.png"} alt="BeatmatchScreenShot"/>
            </Col>
          </Row>
        </Container>
      )
    }
    
  }
}

// ========================================

ReactDOM.render(
  <DJApp/>,
  document.getElementById('root')
);

