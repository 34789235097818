import React from 'react';

class CueButton extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
          active: false
      }
    }

    render() {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="40" fill={"black"} onMouseDown={() => {this.setState({active: true}); this.props.onCueDown();}} onMouseUp={() => {this.setState({active: false}); this.props.onCueUp();}}>
            <rect fill="#2f2f2f" stroke={this.state.active ? "#0dcaf0" : "#9f9f9f"} strokeWidth={2} width="80" height="40" x={0}/>
            <text style={{ userSelect: 'none', fill: this.state.active ? "#0dcaf0" : "white", fontSize: '15' }} y="25" x="50%" textAnchor="middle">CUE</text>
        </svg>
      );
    }
}

export default CueButton;