import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class HotCueClearButton extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
          isEnabled: false,
      }
    }

    render() {
      return (
        <Row>
            <Col>
                <svg width={"100%"} height={"40px"} onMouseDown={() => {this.setState((state) => {this.props.setClearModeEnabled(!state.isEnabled); return ({isEnabled: !state.isEnabled});})}}>
                    <rect fill={this.state.isEnabled ? "black" : "#4f4f4f"} width="100%" height="100%"/>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0" y="12" width="100%" height="16" fill={this.state.isEnabled ? "red" : "white"} viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
                        <path fillRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
                    </svg>
                </svg>
            </Col>
          </Row>
      );
    }
}

export default HotCueClearButton;