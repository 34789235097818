import React from 'react';
import { Row, Col } from 'react-bootstrap';

class HotCue extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
          type: "Position", // Position or Loop
          isSet: false,
          startPosition: 0,
          endPosition: 0,
          wasPlaying: false
      }
    }

    onCueDown = () => {
      if (this.props.isClearModeEnabled) {
        if (this.state.isSet) {
          this.props.clearHotCue(this.props.id);
          this.setState({isSet: false});
        }
      }
      else {
        if (this.state.isSet) {
          this.props.setCurrentTime(this.state.startPosition);
          if (this.props.isPlaying) {
            this.setState({wasPlaying: true});
          }
          else {
            this.props.Play();
          }
        }
        else {
          if (this.props.isPlaying) {
            this.setState({wasPlaying: true});
          }
            const currentTime = parseFloat(this.props.currentTime);
            const hotCuePosition = this.props.snap(currentTime);
            this.props.setHotCue(this.props.id, hotCuePosition);
            this.setState({isSet: true, startPosition: hotCuePosition});

          if (!this.props.isPlaying) {
            if (currentTime !== hotCuePosition) {
              this.props.setCurrentTime(hotCuePosition);
            }
          }           
        }
      }
    }
  
    onCueUp = () => {
      if (!this.props.isClearModeEnabled) {
        if (this.props.isPlaying && !this.state.wasPlaying)
        {
          this.props.Pause();
          this.props.setCurrentTime(this.state.startPosition);
        }
      }
      
      this.setState({wasPlaying: false});
    }

    getFill = () => {
      if (this.state.isSet){
        if (this.props.isClearModeEnabled) {
          return "red";
        }
        else {
          if (this.state.type === "Position") {
            return "#0dcaf0";
          }
          else {
            return "green";
          }
        }
      }
      else {
        return "#4f4f4f";
      }
    }

    render() {
      return (
        <Row>
            <Col>
            <svg width={"100%"} height={"40px"} onMouseDown={this.onCueDown} onMouseUp={this.onCueUp}>
              <rect fill={this.getFill()} width="100%" height="100%"/>
              <text x={10} y="15" textAnchor="middle" style={{ userSelect: 'none', fill: 'white', fontSize: '12' }}>{this.props.id.toString()}</text>
              {this.state.isSet && this.props.isClearModeEnabled ? 
              <svg xmlns="http://www.w3.org/2000/svg" x="0" y="12" width="100%" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
                <path fillRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
              </svg> : <></>}
            </svg>
            </Col>
          </Row>
      );
    }
}

export default HotCue;