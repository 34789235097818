import React from 'react';
import {OverlayTrigger, Popover } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.css';
import 'index.css';

class TempoPercentageDisplay extends React.Component {
  formatTempoChangePercentage = (value) => {
    return (value > 0 ? "+" : "") + (Math.round(1000 * value) / 10).toFixed(1) + "%"
  }

  render() {
      return (
            <OverlayTrigger
                trigger="click"
                placement={"top"}
                overlay={
                    <Popover id="TempoPercentageOptions" style={{backgroundColor: "#6f6f6f"}}>
                    <Popover.Body style={{padding: "0px"}}>
                        {this.props.overlay}
                    </Popover.Body>
                    </Popover>
                }
            >
            <svg width={"60px"} height={"30px"} onClick={() => {}}>
                <rect fill={"#3f3f3f"} width="100%" height="30" x={0}/>
                <text x={"50%"} y="20" textAnchor="middle" style={{ userSelect: 'none', fill: 'white', fontSize: '12' }}>{this.formatTempoChangePercentage(this.props.tempoChangePercentage)}</text>
            </svg>
            </OverlayTrigger>
      )
    }
}

export default TempoPercentageDisplay;