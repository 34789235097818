import React from 'react';
import 'index.css';

function TimeDisplay(props) {
    return (
        <svg width={"60px"} height={"50px"} className="border-start border-dark">
            <rect fill={"#000000"} width="60" height="50" x={0}/>
            <text x={"50%"} y="30" textAnchor="middle" style={{ userSelect: 'none', fill: 'white', fontSize: '12' }}>{props.value}</text>
        </svg> 
    );
}

export default TimeDisplay;



