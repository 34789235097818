import React, { useState } from 'react';
import { Stack } from 'react-bootstrap';
import TempoPercentageDisplay from 'components/TempoPercentageDisplay';
import TempoOptions from 'components/TempoOptions';
import 'bootstrap/dist/css/bootstrap.css';
import 'index.css';

function TempoControls(props) {
    const [tempoFaderPosition, setTempoFaderPosition] = useState(0);
    
    function onTempoFaderChange(value) {
        setTempoFaderPosition(value);
        // value can be 1 to -1, want playback rate to go from 0.92 to 1.08 for +-8%
        const playBackChangePercentage = (-1 * parseFloat(value) * parseFloat(props.tempoRange / 100))
        props.setTempoChangePercentage(playBackChangePercentage);
    }

    function onTempoFaderClick(e) {
        switch (e.detail) {
          case 2:
            onTempoFaderChange(0);
            break;
          default:
            return;
        }
      }

    return (
        <Stack className="float-end">
            <TempoPercentageDisplay tempoChangePercentage={props.tempoChangePercentage} overlay={<TempoOptions tempoRange={props.tempoRange} decrease={props.decreaseTempoRange} increase={props.increaseTempoRange}/>}/>
            <div style={{width: "60px", height: "170px", backgroundColor: "#3f3f3f", overflow: "hidden"}}>
                <input style={{width: "150px"}} type="range" orient="vertical" className="tempoFader" id="tempoFader" min="-1" max="1" value={tempoFaderPosition} step="0.01" onClick={onTempoFaderClick} onChange={(e) => onTempoFaderChange(e.target.value)}/>
            </div>
      </Stack> 
    );
}

export default TempoControls;