import React from 'react';
import {Row, Col, Collapse, Stack} from 'react-bootstrap';
import HotCue from 'components/HotCue';
import HotCueClearButton from 'components/HotCueClearButton';

class HotCues extends React.Component {
    constructor(props) {
      super(props);

      this.state= {
        isOpen: true,
        isClearModeEnabled: false
      }
    }

    setClearModeEnabled = (isClearModeEnabled) => {
      this.setState({isClearModeEnabled: isClearModeEnabled});
    }

    render() {
      return (
        <Row>
          {/* <ButtonGroup style={{width: "100%"}}>
            <Button onClick = {() => {this.setState((state, props) => ({isOpen: !state.isOpen}))}} variant="dark" style={{height: "20px", padding: "0px 0px", fontSize: "10px", borderLeft: "1px solid grey"}}>
                Hot Cues
            </Button>
          </ButtonGroup> */}
          <Collapse in={this.state.isOpen} style={{margin: "10px", marginBottom: "5px"}}>
            <Col>
              <Stack direction="horizontal" gap={2}>
                <HotCue id={1} currentTime={this.props.currentTime} setCurrentTime={this.props.setCurrentTime} setHotCue={this.props.setHotCue} clearHotCue={this.props.clearHotCue} isClearModeEnabled={this.state.isClearModeEnabled} isPlaying={this.props.isPlaying} Play={this.props.Play} Pause={this.props.Pause} snap={this.props.snap}/>
                <HotCue id={2} currentTime={this.props.currentTime} setCurrentTime={this.props.setCurrentTime} setHotCue={this.props.setHotCue} clearHotCue={this.props.clearHotCue} isClearModeEnabled={this.state.isClearModeEnabled} isPlaying={this.props.isPlaying} Play={this.props.Play} Pause={this.props.Pause} snap={this.props.snap}/>
                <HotCue id={3} currentTime={this.props.currentTime} setCurrentTime={this.props.setCurrentTime} setHotCue={this.props.setHotCue} clearHotCue={this.props.clearHotCue} isClearModeEnabled={this.state.isClearModeEnabled} isPlaying={this.props.isPlaying} Play={this.props.Play} Pause={this.props.Pause} snap={this.props.snap}/>
                <HotCue id={4} currentTime={this.props.currentTime} setCurrentTime={this.props.setCurrentTime} setHotCue={this.props.setHotCue} clearHotCue={this.props.clearHotCue} isClearModeEnabled={this.state.isClearModeEnabled} isPlaying={this.props.isPlaying} Play={this.props.Play} Pause={this.props.Pause} snap={this.props.snap}/>
                <HotCue id={5} currentTime={this.props.currentTime} setCurrentTime={this.props.setCurrentTime} setHotCue={this.props.setHotCue} clearHotCue={this.props.clearHotCue} isClearModeEnabled={this.state.isClearModeEnabled} isPlaying={this.props.isPlaying} Play={this.props.Play} Pause={this.props.Pause} snap={this.props.snap}/>
                <HotCue id={6} currentTime={this.props.currentTime} setCurrentTime={this.props.setCurrentTime} setHotCue={this.props.setHotCue} clearHotCue={this.props.clearHotCue} isClearModeEnabled={this.state.isClearModeEnabled} isPlaying={this.props.isPlaying} Play={this.props.Play} Pause={this.props.Pause} snap={this.props.snap}/>
                <HotCue id={7} currentTime={this.props.currentTime} setCurrentTime={this.props.setCurrentTime} setHotCue={this.props.setHotCue} clearHotCue={this.props.clearHotCue} isClearModeEnabled={this.state.isClearModeEnabled} isPlaying={this.props.isPlaying} Play={this.props.Play} Pause={this.props.Pause} snap={this.props.snap}/>
                <HotCue id={8} currentTime={this.props.currentTime} setCurrentTime={this.props.setCurrentTime} setHotCue={this.props.setHotCue} clearHotCue={this.props.clearHotCue} isClearModeEnabled={this.state.isClearModeEnabled} isPlaying={this.props.isPlaying} Play={this.props.Play} Pause={this.props.Pause} snap={this.props.snap}/>
                <HotCueClearButton setClearModeEnabled={this.setClearModeEnabled}/>
              </Stack>
            </Col>
          </Collapse>
        </Row>
      );
    }
}

export default HotCues;