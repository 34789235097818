import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MixerKnob from 'components/MixerKnob';

import 'bootstrap/dist/css/bootstrap.css';
import 'index.css';

class ChannelTrim extends React.Component {
    constructor(props) {
        super(props);
        this.gainRampTime = 0.01;
    }


    handleChange = (value) => {
      const scaledValue = value >= 0 ? 1 + (parseFloat(value)) : 1 + parseFloat(value);
      this.props.gain.gain.cancelScheduledValues();
      this.props.gain.gain.rampTo(scaledValue, this.gainRampTime);
    }
    
    render() {
      return (
        <>
            <Row>
                <Col><MixerKnob size={60} label="Trim" onChange={(value) => this.handleChange(value)}></MixerKnob></Col>
            </Row>
        </>
      )
    }
  }

export default ChannelTrim;