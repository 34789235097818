import React from 'react';

class PlayButton extends React.Component {
    render() {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="40" fill={"black"} onClick={() => this.props.isPlaying ? this.props.Pause() : this.props.Play()}>
            <rect fill="#2f2f2f" stroke={this.props.isPlaying ? "#378805" : "#9f9f9f"} strokeWidth={2} width="80" height="40" x={0}/>
            <path transform="translate(30, 8) scale(1.5, 1.5)" fill={this.props.isPlaying ? "#378805" : "white"} d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
        </svg>
      );
    }
}

export default PlayButton;