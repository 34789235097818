import React from 'react';
import * as d3 from "d3";
import { convertTimeToWaveFormPosition } from 'Utilities'

class D3WaveFormOverview extends React.Component {
   constructor(props){
      super(props);
      this.node = React.createRef();
      this.svgPt = null;
   }   
   
   componentDidMount() {
        this.svgPt = this.node.current.createSVGPoint();
   }   
   
   componentDidUpdate() {
        d3.select(this.node.current)
            .selectChild("#cueMarker")
            .attr("transform", `translate(${this.props.cuePosition})`)

        d3.select(this.node.current)
            .selectChild("#playheadOverlay")
            .attr("transform", `translate(${this.props.playheadPosition})`);
   }

   componentWillUnmount() {
       if (this.clock) {
        this.clock.stop();
        this.clock.dispose();
        this.clock = null;
       }
    }

    mouseMove = (e) => {
        this.svgPt.x = e.clientX;
        this.svgPt.y = e.clientY;
        var cursorpt =  this.svgPt.matrixTransform(this.node.current.getScreenCTM().inverse());

        d3.select(this.node.current)
        .selectChild("#playheadSelector")
        .attr("stroke", "white")
        .attr("transform", `translate(${cursorpt.x})`);
    }

    mouseDown = (e) => {
        this.svgPt.x = e.clientX;
        this.svgPt.y = e.clientY;
        var cursorpt =  this.svgPt.matrixTransform(this.node.current.getScreenCTM().inverse());
        this.props.setPlayhead(cursorpt.x);
    }

    mouseLeave = (e) => {
        d3.select(this.node.current)
        .selectChild("#playheadSelector")
        .attr("stroke", "transparent");
    }

   render() 
   {
        return (
            <svg ref={this.node} width={this.props.width} height={this.props.height} preserveAspectRatio="none" viewBox={[0, 0, this.props.size[0], this.props.size[1]]} style={{backgroundColor: "black"}} onMouseEnter={this.mouseMove} onMouseMove={this.mouseMove} onMouseLeave={this.mouseLeave} onMouseDown={this.mouseDown}>
                <g dangerouslySetInnerHTML={{__html: this.props.waveformPaths}}></g>
                <rect id="loop" display={this.props.loopActive ? "" : "none"} x={convertTimeToWaveFormPosition(this.props.loopStart, this.props.duration, 450)} y="-1" width={convertTimeToWaveFormPosition(this.props.loopEnd - this.props.loopStart, this.props.duration, 450)} height={this.props.size[1] + 2} fill="green" fillOpacity="50%" stroke="green" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
                <rect id="playheadOverlay" x="-100%" y="-1" width="100%" height={this.props.size[1] + 2} fill="black" fillOpacity="50%" stroke="red" strokeWidth="1" vectorEffect="non-scaling-stroke">
                    {this.props.showWarning ? <animate attributeName="fill" values="black;#650000;black" dur="2s" repeatCount="indefinite" /> : <></>}
                </rect>
                <polygon id="cueMarker" pathLength={10} points={`-5,${this.props.size[1]} 0,${this.props.size[1] * 3 / 4} 5,${this.props.size[1]}`} style={{fill: "#0dcaf0"}}/>
                <line id="playheadSelector" x1="0" x2="0" y1="0" y2="100%" stroke="transparent" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
                {this.props.hotCues.map((hc) => {
                    const hcPosition = convertTimeToWaveFormPosition(hc.position, this.props.duration, 450);
                    return (
                    <g key={hc.id}>
                        <line x1={hcPosition} x2={hcPosition} y1="0" y2="100%" stroke="#0dcaf0" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
                        <rect x={hcPosition} y="1" width="14" height="25%" fill="#0dcaf0" fillOpacity="70%" stroke="#0dcaf0" strokeWidth="0" vectorEffect="non-scaling-stroke"/>
                        <text x={hcPosition + 7} y="20%" textAnchor="middle" style={{ userSelect: 'none', fill: 'white', fillOpacity: '75%', fontSize: '20px' }}>{hc.id}</text>
                    </g>
                    );
                })}
            </svg>
        )
   }
}

export default D3WaveFormOverview;