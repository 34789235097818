import React from 'react';
import { Stack } from 'react-bootstrap';
import * as Tone from 'tone';

class MasterMeter extends React.Component {
    constructor(props) {
      super(props);
      this.canvasRefLeft = React.createRef();
      this.canvasRefRight = React.createRef();
      this.clock = null;
      this.canvasContextLeft = null;
      this.canvasContextRight = null;
    }
    
    renderMeter = (context, height) => {
      context.clearRect(0, 0, 160, 6);
      context.fillStyle = '#12c9f0';
      context.fillRect(0, 0, Math.min(height, 30) * (160 / 42), 6);
  
      if (height > 30)
      {
        context.fillStyle = '#ffa652';
        context.fillRect((160 / 42 * 30), 0, (height - 30) * (160 / 42), 6);
      }
  
      // Over 0 should be clipping, but should add a limiter / headroom probably...
      // this.canvasContext.fillStyle = 'red';
      // this.canvasContext.fillRect(0, 200, 10, -1 * height);
    }
  
    componentDidMount()
    {
      this.canvasContextLeft = this.canvasRefLeft.current.getContext('2d');
      this.canvasContextRight = this.canvasRefRight.current.getContext('2d');
      this.clock = new Tone.Clock((time) => {
        this.renderMeter(this.canvasContextLeft, this.props.meter.getValue()[0] + 42);
        this.renderMeter(this.canvasContextRight, this.props.meter.getValue()[1] + 42);
      }, 24);
      this.clock.start();
    }
  
    render()
    {
      return (
        <Stack direction="horizontal" gap={2}>
            <p style={{fontSize: "12px"}}>Master</p>
            <Stack>
                <canvas width="160px" height="6px" className="border border-dark" ref={this.canvasRefLeft} style={{backgroundColor: "black"}}/>
                <canvas width="160px" height="6px" className="border border-dark" ref={this.canvasRefRight} style={{backgroundColor: "black"}}/>
            </Stack>
        </Stack>
      )
    }
  }

export default MasterMeter;