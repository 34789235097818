import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'index.css';

class HeadphoneCueButton extends React.Component {
  render() {
      return (
        <svg width={this.props.width} height={this.props.height} style={this.props.style} className={this.props.className} onMouseDown={() => {this.props.onMouseDown();}} onMouseUp={() => {this.props.onMouseUp();}}>
            <rect fill={this.props.isActive ? "#0f0f0f" : "#4f4f4f"} strokeWidth={0} width="100%" height="100%" />
            <text x={"50%"} y="17.5" textAnchor="middle" style={{ userSelect: 'none', fill: `${this.props.isActive ? "#0dcaf0" : "white"}`, fontSize: '12' }}>{this.props.text}</text>
        </svg>
      )
    }
}

export default HeadphoneCueButton;