import React from 'react';
import { Row, Col, Table, ListGroup, Tab, Modal, Button, Form } from 'react-bootstrap';

import TrackRowList from 'components/TrackRowList';

class Browser extends React.Component {
    constructor(props)
    {
      super(props);
  
      this.state = {
        searchResults: [],
        searchTypingTimeout: 0,
        showModal: false,
        importPlaylistTypingTimeout: 0,
        importPlaylistName: "",
        isPlaylistImportEnabled: false,
        isPlaylistUrlInvalid: false,
        importPlaylistId: "",
        importedPlaylists: [],
        showSearchResults: false,
        activePlaylist: "#Trending",
        selectedPlaylist: "#Trending"
      };
    }
  
    componentDidMount() {
      this.props.audiusClient.GetTrendingTracks().then((response) => {
        this.setState(state => ({importedPlaylists: [...state.importedPlaylists, {Name: "Trending", Data: response.data.data}] }));
      })
    }

    validatePlaylistUrl = (playlistUrl) => {
      const UrlRegex = new RegExp("https://audius.co/.*/playlist/.*");
      return UrlRegex.test(playlistUrl);
    }

    resolvePlaylist = (playlistUrl) => {
      if (this.state.importPlaylistTypingTimeout) {
        clearTimeout(this.state.importPlaylistTypingTimeout);
      }
      
      this.setState(
        {
          importPlaylistTypingTimeout: setTimeout(() => {            
            this.props.audiusClient.Resolve(playlistUrl).then((response) => {
              this.setState({importPlaylistName: response.data.data[0].playlist_name, isPlaylistImportEnabled: true, importPlaylistId: response.data.data[0].id});
            });
          }, 500)
        })
    }

    importPlaylist = () => {
      this.props.audiusClient.GetPlaylist(this.state.importPlaylistId).then((response) => {
        this.setState(state => ({importedPlaylists: [...state.importedPlaylists, {Name: this.state.importPlaylistName, Data: response.data.data}] }));
      })
    }

    handleSearch = (searchTerm) => {
      if (this.state.searchTypingTimeout) {
         clearTimeout(this.state.searchTypingTimeout);
      }
  
      this.setState({
         searchTypingTimeout: setTimeout(() => this.performSearch(searchTerm.trim()), 500)
      });
    }
    
    performSearch = (query) => {
      if(query && query.length !== 0)
      {
        this.setState({showSearchResults: true, activePlaylist: "#searchResults"});
        this.props.audiusClient.Search(query)
        .then(response=>{return response.data.data}, (error) => {this.props.onError(error)})
        .then(results=>{this.setState({searchResults: results})});
      }
      else
      {
        this.setState((state) => ({searchResults: [], showSearchResults: false, activePlaylist: state.selectedPlaylist}));
      }
    }

    render() {
      return (
        <>
          <Row className="g-0">
            <Col className="p-2">
              <input type="search" placeholder=" Search Audius" onChange={(e) => this.handleSearch(e.target.value)}></input>
            </Col>
          </Row>
          <Row className="g-0 border-top border-dark">
            <Tab.Container id="list-group-tabs-example" defaultActiveKey="#Trending" activeKey={this.state.activePlaylist} onSelect={(p) => this.setState({activePlaylist: p, selectedPlaylist: p})}>
              <Row className="g-0">
                <Col className="border-end border-dark" xs={2}>
                  <ListGroup variant="flush">
                    <ListGroup.Item hidden={!this.state.showSearchResults} action href="#searchResults" variant="dark" key="searchResults">
                        Search Results
                    </ListGroup.Item>
                    {this.state.importedPlaylists.map((p) => {
                      return (
                      <ListGroup.Item action href={"#" + p.Name} variant="dark" key={p.Name}>
                        {p.Name}
                      </ListGroup.Item>);
                    })}
                    
                    <ListGroup.Item action variant="dark" onClick={() => this.setState({showModal: true, importPlaylistName: ""})}>
                      + Import Playlist
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
                <Col>
                  <Tab.Content>
                    <Tab.Pane eventKey="#searchResults" key="searchResults">
                        <div style={{ maxHeight: "calc(100vh - 580px)", overflow: "auto"}}>
                          <Table style={{width:'100%', color: 'white'}}>
                            <thead>
                              <tr>
                                <th>Load</th>
                                <th>Title</th>
                                <th>Artist</th>
                                <th>Genre</th>
                                <th>Mood</th>
                                <th>Duration</th>
                              </tr>
                            </thead>
                            <TrackRowList onLoad={this.props.onLoad} tracks={this.state.searchResults}/>
                          </Table>
                        </div>
                    </Tab.Pane>
                    {this.state.importedPlaylists.map((p) => {
                      return (
                        <Tab.Pane eventKey={"#" + p.Name} key={p.Name}>
                        <div style={{ maxHeight: "calc(100vh - 580px)", overflow: "auto"}}>
                          <Table style={{width:'100%', color: 'white'}}>
                            <thead>
                              <tr>
                                <th>Load</th>
                                <th>Title</th>
                                <th>Artist</th>
                                <th>Genre</th>
                                <th>Mood</th>
                                <th>Duration</th>
                              </tr>
                            </thead>
                            <TrackRowList onLoad={this.props.onLoad} tracks={p.Data}/>
                          </Table>
                        </div>
                      </Tab.Pane>);
                    })}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Row>
          <Row>
            <Modal size="lg" centered show={this.state.showModal} style={{color: "black"}} onHide={()=>this.setState({showModal: false, isPlaylistImportEnabled: false, isPlaylistUrlInvalid: false, importPlaylistName: ""})}>
              <Modal.Header closeButton>
                <Modal.Title>
                  Import Playlist
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group as={Row} className="mb-3" controlId="formPlaylistUrl">
                    <Form.Label column sm="3">
                      Audius Playlist Url:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control isInvalid={this.state.isPlaylistUrlInvalid} isValid={this.state.isPlaylistImportEnabled} type="text" placeholder="https://audius.co/audius/playlist/hot-new-on-audius" onChange={(e) => {if (this.validatePlaylistUrl(e.target.value)){this.setState({isPlaylistUrlInvalid: false}); this.resolvePlaylist(e.target.value)} else { this.setState({isPlaylistUrlInvalid: true}) }}}/>
                      <Form.Control.Feedback type="invalid">
                        Please enter a playlist url.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button disabled={!this.state.isPlaylistImportEnabled} variant="dark" onClick={()=>{this.importPlaylist(); this.setState({showModal: false, isPlaylistImportEnabled: false, isPlaylistUrlInvalid: false})}}>Import</Button>
              </Modal.Footer>
            </Modal>
          </Row>
          </>
      )
    }
}

export default Browser;