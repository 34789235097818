import React, { useState } from 'react';
import 'index.css';

function KeyDisplay(props) {
    const [isActive, setIsActive] = useState(true);

    return (
        <svg width={"60px"} height={"35px"} style={{paddingTop: "10px"}} onClick={() => {setIsActive(!isActive); props.toggleKeyLock();}}>
            <g fillOpacity={isActive ? "100%": "30%"}>
                <rect fill={"#3f3f3f"} width="60" height="25"/>
                <text x={"50%"} y="17.5" textAnchor="middle" style={{ userSelect: 'none', fill: props.camelotKey.Color, fontSize: '12' }}>{props.camelotKey.Key}</text>
            </g>
        </svg>    
    );
}

export default KeyDisplay;