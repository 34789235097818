import React from 'react';
import {OverlayTrigger, Popover } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.css';
import 'index.css';

class TempoDisplay extends React.Component {
  formatBpm = (value) => {
    if (value === 0) {return "";}
    return ((Math.round(10 * value) / 10) * (1+this.props.tempoChangePercentage)).toFixed(1);
  }

  render() {
      return (
            <OverlayTrigger
                trigger="click"
                placement={"bottom"}
                overlay={
                    <Popover id="GridOptions" style={{backgroundColor: "#6f6f6f"}}>
                    <Popover.Body style={{padding: "5px"}}>
                        {this.props.overlay}
                    </Popover.Body>
                    </Popover>
                }
            >
            <svg width={"60px"} height={"35px"} style={{paddingTop: "10px"}}>
                <rect fill={"#3f3f3f"} width="60" height="25" x={0}/>
                <text x={"50%"} y="17.5" textAnchor="middle" style={{ userSelect: 'none', fill: 'white', fontSize: '12' }}>{this.formatBpm(this.props.bpm)}</text>
            </svg>
            </OverlayTrigger>
      )
    }
}

export default TempoDisplay;