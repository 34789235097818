const axios = require('axios').default;

class AudiusClient {
    constructor(axios, onError) {
        this.axios = axios;
        this.isInitialized = false;
        this.endpoints = null;
        this.baseUrl = "https://discoveryprovider.audius.co";
        this.onError = onError;
    }

    Initialize = async () => {
        const metadataEndpoint = "https://api.audius.co/";
        try {
            const response = await this.axios.get(metadataEndpoint);
            this.endpoints = response.data.data;
            console.log(this.endpoints);
            this.baseUrl = sample(this.endpoints);
            console.log("Selected: " + this.baseUrl);
            this.isInitialized = true;
        }
        catch (error) {
            this.onError(error);
        }
    }

    StreamTrack = (trackId) => {
        return this.SendRequest('get', `/tracks/${trackId}/stream`, {}, 'arraybuffer');
    }

    GetTrendingTracks = ()  => {
        return this.SendRequest('get', "/tracks/trending", { time: "week" });
    }

    Resolve = (playlistUrl) => {
        return this.SendRequest('get', "/resolve", { url: playlistUrl });
    }

    GetPlaylist = (playlistId) => {
        return this.SendRequest('get', `/playlists/${playlistId}/tracks`, {})
    }

    Search = (searchTerm) => {
        return this.SendRequest('get', "/tracks/search", { query: searchTerm });
    }

    SendRequest = async (method, urlPath, queryParams, responseType = 'json') => {        
        if (!this.isInitialized) {
            await this.Initialize();
        }

        let numRetriesRemaining = 3;
        while (numRetriesRemaining > 0)
        {
            const requestConfig = {
                baseURL: this.baseUrl,
                method: method,
                url: "/v1" + urlPath,
                params: {
                    ...queryParams,
                    app_name: "beatmatch.xyz"
                },
                responseType: responseType,
                timeout: 5000
            }

            try {
                return await axios.request(requestConfig);
            }
            catch (e) {
                console.log(e);
                this.baseUrl = sample(this.endpoints);
                console.log("Selected: " + this.baseUrl);
                numRetriesRemaining--;

                if (numRetriesRemaining === 0) {
                    this.onError(e);
                }
            }
        }
    }
}

function sample(arr) {
    return arr[Math.floor(Math.random() * arr.length)];
}

export default AudiusClient;