import React from 'react';
import TrackRow from 'components/TrackRow';

import 'bootstrap/dist/css/bootstrap.css';

class TrackRowList extends React.Component {
    render()
    {
      let tracks = this.props.tracks;

      // Filter out tracks >5mins as AWS will hit memory limit as of now
      tracks = tracks.filter((track) => track.duration < 300);

      const trackRows = tracks.map((track) => 
        <TrackRow onLoad={this.props.onLoad} key={track.id} id={track.id} artwork={track.artwork ? track.artwork["150x150"] : ""} title={track.title} artist={track.user.name} genre={track.genre} mood={track.mood} duration={track.duration}/>
      )
      return (
        <tbody>
          {trackRows}
        </tbody>
      );
    }
}

export default TrackRowList;