import React from 'react';
import { Col } from 'react-bootstrap'
import * as d3 from "d3";
import ZoomControls from 'components/ZoomControls';
import { convertTimeToWaveFormPosition, TimeToBeats } from 'Utilities';

class D3WaveForm extends React.Component {
   constructor(props){
      super(props);
      this.node = React.createRef();
      this.pan = 0;

      this.state = {
        zoomFactor: 25
      }
   }   
   
    componentDidMount() {
        d3
        .select(this.node.current)
        .call(d3.zoom()
            .on("zoom", (e) => 
            {
                const delta = e.transform.x - this.pan;
                this.pan = e.transform.x;
                this.props.shiftPlayhead(this.props.duration * (delta / (this.props.size[0] * this.state.zoomFactor)))
            })
            .on("start", () => 
            {
                if (this.props.isPlaying) {
                    this.props.pause();
                }
            })
            .on("end", () => 
            {
                if (this.props.isPlaying) {
                    this.props.play();
                }
            }))
        .on("wheel.zoom", null);
   }

    zoomIn = () => {
        this.setState((state) => ({zoomFactor: state.zoomFactor * 1.5}));
    }

    zoomOut = () => {
        this.setState((state) => ({zoomFactor: state.zoomFactor / 1.5}));
    }

   render() 
   {
       let patternWidth = 0.25;
       if (this.props.bpm && this.props.duration)
       {
            patternWidth = 4 / TimeToBeats(this.props.duration, this.props.bpm);
       }
        

        return (
            <>
            <Col xs="auto"><ZoomControls zoomIn={this.zoomIn} zoomOut={this.zoomOut}/></Col>
            <Col>
                <svg ref={this.node} width={this.props.width} height={this.props.height} viewBox={[0, 0, this.state.zoomFactor * this.props.size[0], this.props.size[1]]} preserveAspectRatio="xMidYMid slice" style={{backgroundColor: "black"}}>
                    <defs>
                        <pattern id={"gridPattern" + this.props.deckName} x="0" y="0" width={patternWidth} height="1" patternContentUnits="objectBoundingBox">
                            <line x1={0 * patternWidth / 4} y1="0" x2={0 * patternWidth / 4} y2="1" stroke="white" strokeWidth={0.0025 / this.state.zoomFactor}/>
                            <line x1={1 * patternWidth / 4} y1="0" x2={1 * patternWidth / 4} y2="1" stroke="grey" strokeWidth={0.0025 / this.state.zoomFactor}/>
                            <line x1={2 * patternWidth / 4} y1="0" x2={2 * patternWidth / 4} y2="1" stroke="grey" strokeWidth={0.0025 / this.state.zoomFactor}/>
                            <line x1={3 * patternWidth / 4} y1="0" x2={3 * patternWidth / 4} y2="1" stroke="grey" strokeWidth={0.0025 / this.state.zoomFactor}/>
                            <line x1={4 * patternWidth / 4} y1="0" x2={4 * patternWidth / 4} y2="1" stroke="white" strokeWidth={0.0025 / this.state.zoomFactor}/>
                        </pattern>
                    </defs>
                    <rect id="grid" fill={"url(#gridPattern" + this.props.deckName + ")"} stroke="transparent" width={this.state.zoomFactor * this.props.size[0]} height="100%" transform={`translate(${(this.state.zoomFactor * this.props.size[0] / 2) - (this.state.zoomFactor * (this.props.playheadPosition - this.props.downbeatPosition))})`}/>
                    <g id="waveform" dangerouslySetInnerHTML={{__html: this.props.waveformPaths}} transform={`translate(${(this.state.zoomFactor * this.props.size[0] / 2) - (this.state.zoomFactor * this.props.playheadPosition)}) scale(${this.state.zoomFactor}, 1)`}></g>
                    <polygon id="cueMarker" points={`-7,${this.props.size[1]} 0,${this.props.size[1] - 15} 7,${this.props.size[1]}`} style={{fill: "#0dcaf0"}} transform={`translate(${(this.state.zoomFactor * this.props.size[0] / 2) - (this.state.zoomFactor * (this.props.playheadPosition - this.props.cuePosition))})`}></polygon>
                    {this.props.hotCues.map((hc) => {
                        return (
                        <g key={hc.id} transform={`translate(${(this.state.zoomFactor * this.props.size[0] / 2) - (this.state.zoomFactor * (this.props.playheadPosition - convertTimeToWaveFormPosition(hc.position, this.props.duration)))})`}>
                            <line x1={0} x2={0} y1="1" y2="100%" stroke="#0dcaf0" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
                            <rect x={0} y="1" width="14" height="25%" fill="#0dcaf0" fillOpacity="70%" stroke="#0dcaf0" strokeWidth="0" vectorEffect="non-scaling-stroke"/>
                            <text x={7} y="20%" textAnchor="middle" style={{ userSelect: 'none', fill: 'white', fillOpacity: '75%', fontSize: '20px' }}>{hc.id}</text>
                        </g>
                        );
                    })}
                    <line x1="50%" x2="50%" y1="0" y2="100%" stroke="red" strokeWidth="2" vectorEffect="non-scaling-stroke"></line>
                    <rect id="loop" display={this.props.loopActive ? "" : "none"} fill="green" fillOpacity="50%" width={this.state.zoomFactor * convertTimeToWaveFormPosition(this.props.loopEnd - this.props.loopStart, this.props.duration)} height="100%" transform={`translate(${(this.state.zoomFactor * this.props.size[0] / 2) - (this.state.zoomFactor * (this.props.playheadPosition - convertTimeToWaveFormPosition(this.props.loopStart, this.props.duration)))})`}/>
                </svg>
            </Col>
            </>
        )
   }
}

export default D3WaveForm;