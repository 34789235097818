import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MixerKnob from 'components/MixerKnob';

import 'bootstrap/dist/css/bootstrap.css';
import 'index.css';

class ChannelFilter extends React.Component {
    constructor(props) {
        super(props);

        this.filterRampTime = 0.005;
    }


    handleChange = (value) => {
        this.props.lowPassFilter.frequency.cancelScheduledValues();
        this.props.highPassFilter.frequency.cancelScheduledValues();
        
        if (value === 0) {
            this.props.lowPassFilter.frequency.rampTo(20000, this.filterRampTime);
            this.props.highPassFilter.frequency.rampTo(20, this.filterRampTime);
        } else if (value < 0) {
            this.props.lowPassFilter.frequency.rampTo(20000 - (19980 * parseFloat(Math.pow(-1 * value, 1/8))), this.filterRampTime);
            this.props.highPassFilter.frequency.rampTo(20, this.filterRampTime);
        } else {
            this.props.lowPassFilter.frequency.rampTo(20000, this.filterRampTime);
            this.props.highPassFilter.frequency.rampTo((19980 * parseFloat(Math.pow(value, 8))) + 20, this.filterRampTime);
        }
        
    }
    
    render() {
      return (
        <>
            <Row>
                <Col><MixerKnob size={60} label="Filter" onChange={(value) => this.handleChange(value)}></MixerKnob></Col>
            </Row>
        </>
      )
    }
  }

export default ChannelFilter;