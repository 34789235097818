import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ChannelEQ from 'components/ChannelEQ';
import ChannelFilter from 'components/ChannelFilter';
import ChannelMeter from 'components/ChannelMeter';
import HeadphoneCueButton from 'components/HeadphoneCueButton';
import ChannelTrim from 'components/ChannelTrim';

import 'bootstrap/dist/css/bootstrap.css';
import 'index.css';
import * as Tone from 'tone';

class MixerChannel extends React.Component {
  constructor(props) {
    super(props);

    this.isHeadphoneCueEnabled = false;
    this.trim = new Tone.Gain(1);
    this.eq = new Tone.EQ3();
    this.lowPassFilter  = new Tone.Filter({frequency: 20000, type: "lowpass", Q: 5});
    this.highPassFilter = new Tone.Filter({frequency: 20, type: "highpass", Q: 5});
    this.meter = new Tone.Meter();
    Tone.connectSeries(props.inputNode, this.trim, this.eq, this.lowPassFilter, this.highPassFilter, this.meter, this.props.outputNodes[0]);

    this.state = {
      isHeadphoneCueEnabled: false
    }
  }   

  toggleHeadphoneCue = () => {
    this.isHeadphoneCueEnabled = !this.isHeadphoneCueEnabled;
    this.setState((prevState) => ({isHeadphoneCueEnabled: !prevState.isHeadphoneCueEnabled}));
    if (this.props.outputNodes.length > 1) {
      if (this.isHeadphoneCueEnabled) {
        this.meter.connect(this.props.outputNodes[1])
      } else {
        this.meter.disconnect(this.props.outputNodes[1])
      }
    }
  }

  render() {
    if (this.props.mixerSide === "Left")
    {
        return (
            <>
              <Row>
                <Col className="pe-2" align="center" xs="auto">
                  <div className="meter" style={{width: "60px", overflow: "hidden"}}>
                    <ChannelTrim size={50} label="Trim" gain={this.trim}/>
                    <HeadphoneCueButton className="headphoneCueButton" width="50px" height="25px" text="CUE" onMouseDown={this.toggleHeadphoneCue} onMouseUp={() => {}} isActive={this.state.isHeadphoneCueEnabled}/>
                    <input style={{width: "165px"}} type="range" orient="vertical" className="upFader" id="gainDeckA" min="0" max="1" defaultValue="0" step="0.01" onChange={(e) => this.props.onUpFaderChange("A", e.target.value)}/>
                    <ChannelMeter meter={this.meter}/>
                  </div>
                </Col>
                <Col className="ps-2" align="center">
                  <Row><h3 style={{color: "white", userSelect: 'none'}}>{this.props.channelName}</h3></Row>
                  <ChannelEQ eq={this.eq}/>
                  <ChannelFilter lowPassFilter={this.lowPassFilter} highPassFilter={this.highPassFilter} />
                </Col>
              </Row>
            </>
          )
    } 
    else 
    {
      return (
        <Row>
          <Col className="pe-2" align="center">
            <Row>
              <h3 style={{color: "white", userSelect: 'none'}}>{this.props.channelName}</h3>
            </Row>
            <ChannelEQ eq={this.eq}/>
            <ChannelFilter lowPassFilter={this.lowPassFilter} highPassFilter={this.highPassFilter} />
          </Col>
          <Col className="ps-2" align="center" xs="auto">
            
            <div className="meter" style={{width: "60px", overflow: "hidden"}}>
              <ChannelTrim size={50} label="Trim" gain={this.trim}/>
              <HeadphoneCueButton className="headphoneCueButton" width="50px" height="25px" text="CUE" onMouseDown={this.toggleHeadphoneCue} onMouseUp={() => {}} isActive={this.state.isHeadphoneCueEnabled}/>
              <input style={{width: "165px"}} type="range" orient="vertical" className="upFader" id="gainDeckB" min="0" max="1" defaultValue="0" step="0.01" onChange={(e) => this.props.onUpFaderChange("B", e.target.value)}/>
              <ChannelMeter meter={this.meter}/>
            </div>
          </Col>
        </Row>
        )
    }
  }
}

export default MixerChannel;