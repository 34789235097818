import React from 'react';
import {Stack} from 'react-bootstrap'
import SvgButton from 'components/SvgButton';
import { BeatsToTime } from 'Utilities';

class GridOptions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isJumpLeftActive: false,
            isJumpRightActive: false
        }
    }

    render() {
      return (
          <div>
            <Stack>
              <svg width={"230px"} height={"25px"} onMouseDown={() => {this.props.toggleSnapToGrid()}}>
                <g fillOpacity={this.props.snapToGrid ? "100%" : "30%"}>
                  <rect fill={"#3f3f3f"} fillOpacity="100%" width="100%" height="25" strokeWidth={this.props.snapToGrid ? "1" : "0"} stroke="white"/>
                  <text x={"50%"} y="17.5" textAnchor="middle" style={{ userSelect: 'none', fill: 'white', fontSize: '12' }}>Snap To Grid</text>
                </g>
              </svg>
            </Stack>
            <Stack direction="horizontal" gap={0}>
              <SvgButton width={"70px"} height="25px" text="/2" onMouseDown={() => {this.props.setBpm(0.5 * this.props.trackBpm)}} onMouseUp={() => {}}/>
              <input type="number" id="bpm" name="bpm" value={parseFloat(this.props.trackBpm).toFixed(2)} onChange={(e) => this.props.setBpm(e.target.value)} style={{width: "90px", height: "25px", paddingTop: "0px", marginTop: "0px", fontSize: "10px", textAlign: "center"}}/>
              <SvgButton width={"70px"} height="25px" text="x2" onMouseDown={() => {this.props.setBpm(2 * this.props.trackBpm)}} onMouseUp={() => {}}/>
            </Stack>
            <Stack direction="horizontal" gap={0}>
              <SvgButton width={"70px"} height="25px" text="Shift Left" onMouseDown={() => {this.props.setDownbeatPosition((this.props.downbeatPosition - 0.01) % ((4 * 60) / this.props.trackBpm))}} onMouseUp={() => {}}/>
              <SvgButton width={"90px"} height="25px" text="Set Downbeat" onMouseDown={() => {this.props.setDownbeatPosition(this.props.currentTime % BeatsToTime(4, this.props.trackBpm))}} onMouseUp={() => {}}/>
              <SvgButton width={"70px"} height="25px" text="Shift Right" onMouseDown={() => {this.props.setDownbeatPosition((this.props.downbeatPosition + 0.01) % ((4 * 60) / this.props.trackBpm))}} onMouseUp={() => {}}/>             
            </Stack>
            <Stack direction="horizontal" gap={2}>
              <SvgButton width={"230px"} height="25px" text="Reset" onMouseDown={() => {this.props.setBpm(this.props.initialBpm); this.props.setDownbeatPosition(0);}} onMouseUp={() => {}}/>
            </Stack>
          </div>
      );
    }
}

export default GridOptions;