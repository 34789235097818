import React from 'react';
import { Stack, Image } from 'react-bootstrap/';
import 'bootstrap/dist/css/bootstrap.css';
import 'index.css';
import 'styles/TrackInfo.css'

class TrackInfo extends React.Component {
  render() {
      return (
          <Stack direction="horizontal" className={this.props.className}>
            <Image className="track-info-album-art-beatmatch" rounded src={this.props.artworkSrc}/>
            <Stack className="m-2">
                <p style={{fontSize: "16px"}}>{this.props.title}</p>
                <p style={{color: "#9f9f9f"}}>{this.props.artist}</p>
            </Stack>
          </Stack>
      )
    }
}

export default TrackInfo;